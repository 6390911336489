import { ref } from "vue";

export function useMenuItems() {
  const menuItems = ref([
    {
      route: "about",
      text: "About Us",
      numeral: "i",
      textShort: "About",
    },
    {
      route: "accommodation",
      text: "Our Camps",
      numeral: "ii",
      textShort: "Camps",
    },
    {
      route: "experience",
      text: "Experience",
      numeral: "iii",
    },
    {
      route: "tours",
      text: "Tours",
      numeral: "iv",
    },
    {
      route: "retreats",
      text: "Retreats",
      class: "hidden lg:flex",
      numeral: "v",
    },
    {
      route: "impact",
      text: "Impact",
      class: "hidden lg:flex",
      numeral: "vi",
    },
    {
      route: "contact",
      text: "Connect",
      class: "hidden xl:flex",
      numeral: "vii",
    },
    {
      route: "blog",
      text: "Blog",
      class: "hidden xl:flex",
      external: "https://blog.londolozi.com/",
      numeral: "viii",
    },
  ]);

  return {
    menuItems,
  };
}
